[data-page="testimonials"] {
    .testimonial-name {
        font-weight: $fontBold;
        display: block;
        margin: 10px 0 0 0;
    }
    .testimonial-pic {
        display: block;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        margin: 10px auto 0 auto;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}
