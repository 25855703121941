#splideHeaderVideo {
    // Overrides for SPLIDE
    .splide__slide {
        height: calc(100vh - 142px);
        @include for-custom-break($tablet-portrait-start, $tablet-landscape-end) {
            height: calc(100vh - 150px);
        }
        @include for-phone-only {
            height: calc(100vh - 180px);
        }
    }
}

#splideHeaderImages {
    .slide-tag {
        position: absolute;
        left: 0;
        bottom: 40px;
        background-color: rgba(black, 0.8);
        color: white;
        padding: 20px 40px;
        line-height: 1.2;
        font-weight: $fontBold;

        @include for-phone-only {
            padding: 20px;
        }

        .heading-one {
            font-size: 40px;
            font-weight: $fontBlack;

            @include for-phone-only {
                font-size: 30px;
            }
        }
        .heading-two {
            font-size: 14px;
            margin-bottom: 10px;

            @include for-phone-only {
                font-size: 12px;
            }
        }
    }

    // Overrides for SPLIDE
    .splide__slide {
        height: calc(100vh - 142px);
        @include for-custom-break($tablet-portrait-start, $tablet-landscape-end) {
            height: calc(100vh - 150px);
        }
        @include for-phone-only {
            height: calc(100vh - 180px);
        }
        img {
            object-fit: cover;
            width: 100vw;
            height: 100%;
        }
    }
}

#splideFeatureProjectsVideo {
    // Overrides
    .splide__arrow {
        background: transparent;
        svg {
            width: 40px;
            height: 40px;
            fill: white;
            transition: fill 0.2s linear;
        }
    }
    .splide__track {
        padding: 50px 0;
    }
}

#splideTestimonials {
    .testimonial {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        font-size: 16px;
        padding: 0 100px;
        @include for-phone-only {
            padding: 0 40px;
        }

        .customer-img {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            width: 100px;
            height: 100px;
            border-radius: 50px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
        }
        &.testimonial-one .customer-img {
            background-image: url(../assets/images/home-page/testimonial-1.jpg);
        }
        &.testimonial-two .customer-img {
            background-image: url(../assets/images/home-page/testimonial-2.jpg);
        }
        &.testimonial-three .customer-img {
            background-image: url(../assets/images/home-page/testimonial-3.jpg);
        }
        &.testimonial-four .customer-img {
            background-image: url(../assets/images/home-page/testimonial-4.jpg);
        }
        .customer-testimonial {
            margin: 15px 0;
            color: $text-color-light;
            font-weight: $fontLight;
            display: flex;

            &::before,
            &::after {
                content: " ";
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                width: 40px;
                height: 40px;
                flex: 0 0 40px;
                @include for-phone-only {
                    flex: 0 0 20px;
                }
            }
            &::before {
                background-image: url(../assets/images/icons/quote-left-yellow.svg);
                margin-right: 20px;
            }
            &::after {
                background-image: url(../assets/images/icons/quote-right-yellow.svg);
                margin-left: 20px;
            }

            span {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .customer-name {
            font-weight: $fontBold;
        }
    }

    // Overrides
    .splide__arrow {
        background: transparent;
        svg {
            width: 40px;
            height: 40px;
            fill: $primary-color;
            transition: fill 0.2s linear;
        }
    }
    .splide__slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
