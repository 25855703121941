[data-page="shade-page"] {
    .interior-footer-links {
        display: grid;
        grid-template-columns: repeat(4, 200px);
        grid-gap: 10px;
        justify-content: center;

        @include for-tablet-portrait-only {
            grid-template-columns: repeat(2, 200px);
        }

        @include for-phone-only {
            grid-template-columns: 1fr;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $text-color;
            padding: 10px 20px;
            transition: background-color 0.2s;
            text-decoration: none;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            font-weight: $fontBold;

            &:hover,
            &:focus,
            &:active {
                color: white;
                background-color: $primary-color;
            }
        }
    }
}
