%content-section {
  width: 100%;
  max-width: $body-max-width;
  margin: 0 auto;
  padding: $body-padding-vertical $body-padding-horizontal;
}

%button-reset {
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  line-height: normal;
}