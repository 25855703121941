// Colors
$primary-color: #1B2B56;
$secondary-color: #444;
$accent-color: #FBAD18;
$alt-color: #F2CF7E;
$option-color: #222222;
$text-color: #222;
$text-color-light: #666;
$error-color: red;
$error-bg-color: rgba(red, .10);
$border-color: #ddd;

// fonts
$font: 'Roboto', sans-serif;
$fontLight: 300;
$fontRegular: 400;
$fontBold: 700;
$fontBlack: 900;

// Layout
$border-radius: 4px;
$body-padding-horizontal: 20px;
$body-padding-vertical: 40px;
$body-max-width: 1100px + ($body-padding-horizontal * 2);


// breakpoints
$desktop-up: 1200px;
$tablet-landscape-end: 1199px;
$tablet-landscape-start: 900px;
$tablet-portrait-end: 899px;
$tablet-portrait-start: 600px;
$phone-end: 599px;


