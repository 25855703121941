*,
*:before,
*:after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: $font;
    background: #fff;
    font-size: 16px;
    box-sizing: border-box;
    color: $text-color;
    line-height: 1.4;
}
.hidden {
    display: none !important;
}
p {
    margin: 0;
}
a {
    font-weight: $fontBold;
    color: #06f;
}
strong {
    font-weight: $fontBold;
}
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.content-section-wrapper {
    &.content-section-bg-a {
        background-color: white;
    }
    &.content-section-bg-b {
        background-color: rgba($primary-color, 0.1);
    }
    &.content-section-bg-c {
        background-color: $primary-color;
        .section-title {
            h2 {
                color: white;
            }
            p {
                background: rgba(white, 0.1);
            }
        }
    }
}
.content-section {
    @extend %content-section;
}
.section-title {
    margin: 0 0 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h2 {
        font-size: 40px;
        margin: 0 50px 10px 50px;
        color: $primary-color;
        font-weight: $fontLight;
        @include for-tablet-landscape-only {
            font-size: 34px;
        }
        @include for-tablet-portrait-only {
            font-size: 30px;
        }
        @include for-phone-only {
            font-size: 26px;
        }
        span {
            font-weight: $fontBold;
        }
    }
    p {
        width: 80%;
        background: rgba($primary-color, 0.1);
        height: 4px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: " ";
            background-color: $accent-color;
            border-radius: 10px;
            height: 10px;
            width: 10px;
        }
    }
}

.paragraph-text {
    color: $text-color-light;
    text-align: center;
    font-weight: $fontLight;

    & + .paragraph-text {
        margin-top: 30px;
    }
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;

    iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
}

// Links
.link {
    font-weight: $fontBold;
}

.link-slider-more {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    padding: 5px 10px;
    border-radius: $border-radius;
    transition: all 0.2s;
    text-decoration: none;
    background-color: $accent-color;
    color: white;
    font-size: 12px;
    padding: 10px 20px;
}

// Image Grid
.img-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, 310px);
    grid-gap: 30px;
    justify-content: center;

    &.dense {
        // grid-auto-rows: 200px;
        grid-auto-flow: dense;
    }

    &.margin-top {
        margin-top: 30px;
    }

    img {
        margin: 0; // reset
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

        &:hover {
            box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
        }

        a {
            display: flex;
        }
    }

    .horizontal-span-2 {
        grid-column: span 2;
    }

    .vertical-span-2 {
        grid-row: span 2;
    }
}