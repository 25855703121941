footer {
    background-color: $alt-color;
    color: $option-color;
    font-size: 12px;

    .inner-footer {
        @extend %content-section;
    }

    .footer-grid {
        display: grid;
        grid-template-columns: 270px 150px 1px repeat(3, 1fr);
        grid-template-areas: 
        "companyDetails contactQuote separator products aboutUs contactUs";
        grid-gap: 20px;

        @include for-custom-break(700px, 849px) {
            grid-template-columns: 160px 140px 1px repeat(3, 1fr);
        }
        @include for-custom-break(0, 699px) {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto 1px repeat(3,auto);
            grid-template-areas:
            "companyDetails"
            "contactQuote"
            "separator"
            "products"
            "aboutUs"
            "contactUs";
        }
    }
    
    .footer-links {
        @include for-custom-break(0, 699px) {
            text-align: center;
            font-size: 16px;
        }
        .title {
            font-weight: $fontBold;
            margin-bottom: 8px;
        }
        a {
            color: rgba($option-color, 0.6);
            display: block;
            text-decoration: none;
            font-weight: $fontLight;
            padding: 2px 0;

            &:hover,
            &:focus,
            &:active {
                color: $option-color;
            }
        }

        &.fl-about {
            grid-area: aboutUs;
        }
        
        &.fl-products {
            grid-area: products;
        }
        
        &.fl-contact {
            grid-area: contactUs;
        }
    }

    .separator {
        grid-area: separator;
        &::before {
            content: " ";
            background-color: rgba($option-color, 0.3);
            width: 100%;
            height: 1px;
            display: block;
        }
    }
    .contact-quote-section {
        grid-area: contactQuote;

        @include for-custom-break(0, 699px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .text-us {
            color: $option-color;
            margin-top: 15px;

            a {
                text-decoration: none;
                color: $option-color;
                font-weight: $fontBold;
            }
        }

        .social {
            display: flex;
            margin-top: 15px;
            align-items: center;
            .social-link {
                display: block;
                &:first-child {
                    width: 24px;
                    height: 24px;
                    margin-right: 20px;
                }
                &:last-child {
                    width: 30px;
                    height: 30px;
                }
            }
        }

    }

    .company-details {
        grid-area: companyDetails;
        @include for-custom-break(0, 699px) {
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            font-size: 16px;
        }
    }

    .address-cont {
        margin-top: 20px;
        font-size: 12px;
        color: rgba($option-color, 0.6);
        margin-bottom: 15px;
    }

    .phone-cont {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2px;
        font-size: 12px;

        a {
            text-decoration: none;
            color: $option-color;
            font-weight: $fontBold;
        }
    }

    .logo {
        grid-area: logo;
        width: 175px;
        margin: -5px 30px 0 0;
        @include for-tablet-landscape-down {
            margin: -5px 0 0 0;
        }
        @include for-custom-break($tablet-portrait-start, $tablet-landscape-end) {
            width: 150px;
        }
        @include for-custom-break(450px, 599px) {
            width: 125px;
        }
        @include for-custom-break(0, 699px) {
            width: 180px;
        }
        .logo-cls-1 {
            fill: $accent-color;
        }
        .logo-cls-2,
        .logo-cls-3 {
            fill: $primary-color;
        }
        .logo-cls-3 {
            font-size: 16px;
            font-weight: 800; 
        }
    }
    .footer-tagline {
        font-weight: $fontBold;
        font-size: 12px;
        font-style: italic;
        color: $primary-color;
    }
    .credentials-logos {
        background-color: white;
        padding: 20px $body-padding-horizontal;
        text-align: center;
        img {
            width: 100%;
            max-width: 661px;
        }
    }
    .copyright {
        padding: 10px $body-padding-horizontal;
        background-color: #eee;
        color: $text-color-light;
        font-size: 10px;
        text-align: center;
    }
}