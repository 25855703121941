[data-page="wind-load-chart-page"] {
    .wind-load-chart {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        justify-content: center;

        p {
            border: 1px solid black;
            padding: 10px;
        }

        .height {
            grid-column: 1 / -1;
            background-color: black;
            color: white;
            font-weight: $fontBold;
            text-align: center;
            border-bottom: 1px solid white;
        }
        .height-val {
            color: white;
            font-weight: $fontBold;
            text-align: center;
            background-color: black;

            &.border {
                border-left: 1px solid white;
                border-right: 1px solid white;
            }
        }
        .width {
            grid-row: 3 / span 10;
            grid-column: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: white;
            font-weight: $fontBold;
            span {
                transform: rotate(-180deg);
                writing-mode: tb-rl;
                width: 20px;
                display: block;
            }
        }
        .width-val {
            font-weight: $fontBold;
            background-color: white;
        }
        .speed {
            grid-row: 3 / span 10;
            grid-column: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background-color: lightblue;
            font-weight: $fontBold;
            span {
                writing-mode: vertical-lr;
                display: block;
            }
        }
        .speed-val {
            background-color: lightblue;
        }
    }       
}