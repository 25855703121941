// .modal-container {
//     position: fixed;
//     left: 0;
//     right: 0;
//     top: 0;
//     bottom: 0;
//     background-color: rgba(0, 0, 0, 0.5);
//     z-index: 100;
//     overflow-y: auto;
//     display: none;
// }
// .modal {
//     background-color: #fff;
//     width: 100%;
//     max-width: 500px;
//     margin: 50px auto;
//     position: relative;
// }
// .modal-close {
//     position: absolute;
//     top: 10px;
//     right: 15px;
//     font-size: 40px;
//     text-decoration: none;
//     color: #000;
//     z-index: 10;
// }
.modal-open {
  overflow-y: hidden;
}
