.icon {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 100%;
    display: block;
}
.icon-phone {
    background-image: url(../assets/images/icons/mobile-phone-yellow.svg);
}
.icon-search {
    background-image: url(../assets/images/icons/search-yellow.svg);
}
.icon-nav {
    background-image: url(../assets/images/icons/nav.svg);
}
.icon-close-white {
    background-image: url(../assets/images/icons/close-white.svg);
}
.icon-close-yellow {
    background-image: url(../assets/images/icons/close-yellow.svg);
}
.icon-facebook {
    background-image: url(../assets/images/icons/facebook.svg);
}
.icon-youtube {
    background-image: url(../assets/images/icons/youtube.svg);
}
.icon-instagram {
    background-image: url(../assets/images/icons/instagram.svg);
}


// Arrows
.icon-arrow {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: transparent;
    text-indent: -9999px;
    transition: all 250ms ease-in-out;
    text-decoration: none;
    color: transparent;
    transform: rotate(-45deg);
}
.icon-arrow-down {
    border-bottom: 2px solid #ccc;
    border-left: 2px solid #ccc;
}
