.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    outline: none;
    appearance: none;
    padding: 10px 15px;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    transition: all 0.2s;
    color: #fff;
    font-weight: $fontBold;
    background-color: $accent-color;
    text-transform: uppercase;

    &:hover {
        background: rgba($accent-color, 0.9);
    }
}

.btn-alt-color {
    background: $primary-color;
    &:hover {
        background: rgba($primary-color, 0.9);
    }
}

.btn-slider-more {
    color: white;
    font-size: 12px;
    padding: 10px 20px;
}

.btn-footer-quote {
    color: white;
    background-color: $primary-color;
    font-size: 12px;
    padding: 10px 20px;
    text-decoration: none;
    display: inline-block;

    &:hover {
        background: rgba($primary-color, 0.9);
    }
}

.btn-contact-submit {
    width: 200px;
    @include for-phone-only {
        width: 100%;
    }
}

.btn-processing {
    .btn-text {
        display: none;
    }
    .btn-spinner {
        display: block;
    }
}

.btn-spinner,
.btn-spinner:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.btn-spinner {
    display: none;
    margin: 0 auto;
    font-size: 2px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spinner 1.1s infinite linear;
    animation: spinner 1.1s infinite linear;
}
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}