[data-page="about-us"] {
    .meet-team-cont {
        text-align: center;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        width: 100%;
        max-width: 375px;
        height: 280px;
        margin: 0 auto 30px auto;

        @include for-custom-break(0, 690px) {
            max-width: 310px;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .about-us-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, 310px);
        grid-gap: 30px;
        justify-content: center;

        article {
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            display: flex;
            flex-direction: column;

            p {
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                color: white;
                background-color: $option-color;
                padding: 15px;
                font-weight: $fontLight;
                text-align: center;
            }
        }
    }
}
