[data-page="home"] {
    .preview-cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
        @include for-custom-break($tablet-portrait-start, 1099px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include for-phone-only {
            grid-template-columns: 1fr;
        }

        .preview-card {
            border: 1px solid $border-color;
            display: flex;
            flex-flow: column nowrap;
            picture {
                width: 100%;
                height: 200px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .summary {
                padding: 25px;
                text-align: center;

                .heading {
                    font-weight: $fontBold;
                    font-size: 20px;
                    margin-bottom: 5px;
                }
                .text {
                    color: $text-color-light;
                }
            }
            .link-cont {
                padding: 0 25px 25px 25px;
                margin-top: auto;
            }
            .a-learn-more {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $text-color;
                padding: 10px 20px;
                transition: background-color 0.2s;
                text-decoration: none;
                border: 1px solid $border-color;
                border-radius: $border-radius;
                font-weight: $fontBold;

                &:hover,
                &:focus,
                &:active {
                    color: white;
                    background-color: $primary-color;
                }
            }
        }
    }
    .in-ex-examples {
        margin: 30px auto 0 auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
        align-content: center;
        justify-content: center;
        width: 100%;

        @include for-custom-break(450px, 899px) {
            grid-template-columns: repeat(2, 1fr);
            max-width: 630px;
        }
        @include for-custom-break(0, 449px) {
            grid-template-columns: 1fr;
            max-width: 100%;
        }

        img {
            width: 100%;
            max-width: 300px;

            @include for-custom-break(0, 449px) {
                max-width: 100%;
            }
        }
    }

    .featured-projects {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        color: white;
        text-align: center;
        justify-content: center;
        margin: 0 auto;
        max-width: 850px;

        @include for-phone-only {
            grid-template-columns: 1fr;
        }

        .title {
            font-size: 20px;
            margin: 20px 0 10px 0;
            font-weight: $fontBold;

            @include for-phone-only {
                font-size: 16px;
            }
        }
        .description {
            font-weight: $fontLight;

            a {
                color: white;
                font-weight: $fontBold;
            }
        }
    }

    .featured-project-videos {
        width: 100%;
        // max-width: 1145px;
        margin: 0 auto;
    }

    .featured-videos {
        display: grid;
        grid-template-columns: repeat(auto-fill, 320px);
        grid-gap: 20px;
        color: white;
        text-align: center;
        justify-content: center;
        margin: 0 auto;

        @include for-phone-only {
            grid-template-columns: 1fr;
        }
    }

    .view-all-links {
        text-align: center;
        margin-top: 30px;
        a {
            font-size: 16px;
            text-decoration: none;
        }
        &.white {
            a {
                color: white;
            }
        }
    }
}
