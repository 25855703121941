@mixin for-desktop-up() {
  @media (min-width: $desktop-up) { 
    @content;
  }
}
@mixin for-tablet-landscape-up() {
  @media (min-width: $tablet-landscape-start) { 
    @content;
  }
}
@mixin for-tablet-landscape-down() {
  @media (max-width: $tablet-landscape-end) { 
    @content;
  }
}
@mixin for-tablet-landscape-only() {
  @media (min-width: $tablet-landscape-start) and (max-width: $tablet-landscape-end) { 
    @content;
  }
}
@mixin for-tablet-portrait-up() {
  @media (min-width: $tablet-portrait-start) { 
    @content;
  }
}
@mixin for-tablet-portrait-down() {
  @media (max-width: $tablet-portrait-end) { 
    @content;
  }
}
@mixin for-tablet-portrait-only() {
  @media (min-width: $tablet-portrait-start) and (max-width: $tablet-portrait-end) { 
    @content;
  }
}
@mixin for-phone-only() {
  @media (max-width: $phone-end) { 
    @content;
  }
}
@mixin for-custom-break($min, $max) {
  @media (min-width: $min) and (max-width: $max) { 
    @content;
  }
}