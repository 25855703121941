[data-page="idea-gallery-page"] {
  .idea-gallery-video-wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto 30px auto;
  }
  .color-changer-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  .changer-swatches {
    .instructions {
      margin-bottom: 20px;
    }
    .changer-swatch-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(83px, max-content));
      grid-gap: 20px;
      grid-auto-rows: auto;
      justify-content: center;
    }
  }
  .changer-house {
    margin: 0 auto;
    img {
      width: 100%;
      max-width: 362px;
    }
  }
}
