[data-page="motors-fabrics-page"] {
    .fabrics-gallery {
        display: grid;
        grid-template-columns: repeat(auto-fill, 300px);
        justify-content: center;
        grid-gap: 20px;
        margin-top: 20px;
        @include for-custom-break(0, 675px) {
            grid-template-columns: 1fr;
        }
        .swatch-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
            .swatch {
                width: 260px;
                height: 260px;
                margin: 5px;
                border-radius: 50%;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                overflow: hidden;
                border: 2px solid white;
                @include for-custom-break(0, 675px) {
                    width: 100%;
                    max-width: 375px;
                    height: 300px;
                    border-radius: 6px;
                    margin: 0 auto;
                }

                &:hover {
                    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
                    width: 270px;
                    height: 270px;
                    margin: 0;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            .name {
                margin: 10px 0;
                font-size: 20px;
                font-weight: $fontLight;
                text-align: center;
            }
            .btn-swatch-save {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                padding: 5px 10px;
                background-color: $primary-color;
                transition: background-color 0.2s;
                text-decoration: none;
                border: 1px solid $border-color;
                border-radius: $border-radius;
                font-weight: $fontBold;
                width: 100%;
                max-width: 120px;
                margin: 0 auto;
                font-size: 12px;
                text-transform: uppercase;

                &:hover,
                &:focus,
                &:active {
                    background-color: rgba($primary-color, 0.7);
                }
            }
        }
    }
    .fabrics-gallery-two {
        margin-top: 100px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 350px);
        justify-content: center;
        grid-gap: 20px;
        .swatch-wrapper {
            display: flex;
            align-items: center;
            flex-direction: column;
            .swatch {
                img {
                    width: 100%;
                    max-width: 350px;
                }
            }
        }
    }
}
