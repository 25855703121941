[data-page="contact-us"] {
    .address-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
        grid-gap: 50px;
        align-items: center;
        
        @include for-phone-only {
            grid-template-columns: repeat(auto-fit, minmax(325px, 1fr));
        }

        .address {
            > p {
                &:first-child {
                    color: $primary-color;
                    font-size: 30px;
                    font-weight: $fontBlack;
                    margin-bottom: 3px;
                }
            }
            .phone-numbers {
                display: grid;
                grid-template-columns: repeat(auto-fit, 110px);
                grid-gap: 20px;
                margin-top: 10px;
                padding-top: 10px;
                border-top: 1px solid #ccc;

                a {
                    color: $text-color;
                    text-decoration: none;
                    font-weight: $fontLight;
                }

                p {
                    font-size: 16px;
                    font-weight: $fontLight;
                    span {
                        display: block;
                        font-size: 12px;
                        font-weight: $fontBold;
                    }
                }
            }
        }
    }
    .contact-form {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        label {
            // font-size: 16px;
            font-weight: 400;
            margin-bottom: 2px;
            display: block;
        }
        input[type="text"],
        select,
        textarea {
            border: 1px solid #999;
            padding: 5px;
            border-radius: 4px;
            min-height: 34px;
            color: #333;
            width: 100%;
        }

        .grid-span-all {
            grid-column: 1 / -1;
        }

        .grid-span-2 {
            grid-column: span 2;
        }
    }
    .success-message {
        font-weight: 300;
        font-size: 30px;
        padding: 30px 0;
        text-align: center;
    }
    .validation-error-msg {
        background-color: red;
        color: white;
        font-weight: bold;
        border-radius: 3px;
        padding: 10px;
        margin-bottom: 10px;
        width: 100%;
    }

    .input-error {
        input[type="text"],
        select,
        textarea {
            border-color: red;
            color: red;
            background-color: rgba(255, 0, 0, 0.1);
        }
        label {
            color: red;
        }
        .input-error-msg {
            display: block;
        }
    }

    .input-error-msg {
        display: none;
        color: red;
        font-size: 10px;
        margin-top: 3px;
    }

    // Map Size 
    .map {
        height: 350px;
        width: 100%;
        border: 0;
    }
}
