[data-page="diy-page"] {
    .diy-shade-wrapper {
        display: grid;
        align-items: center;
        grid-gap: 50px;
        justify-items: center;

        &.left {
            grid-template-columns: 400px 1fr;
            @include for-custom-break(0, 799px) {
                grid-template-columns: 1fr;
            }
        }
        &.right {
            grid-template-columns: 1fr 400px;
            @include for-custom-break(0, 799px) {
                grid-template-columns: 1fr;
                .img-wrapper {
                    order: 1;
                }
                p {
                    order: 2;
                }
            }
        }
        .img-wrapper {
            width: 100%;
            height: 300px;
            overflow: hidden;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            @include for-custom-break(0, 799px) {
                max-width: 400px;
            }
            @include for-custom-break(0, 485px) {
                box-shadow: none;
                height: auto;
            }
            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }
    }
    .prod-compare-img {
        margin: 30px auto 0 auto;
        background-image: url(../assets/images/diy/prod-comparison.jpg);
        background-size: cover;
        background-position: bottom;
        height: 250px;
        width: 100%;
        max-width: 400px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
}
