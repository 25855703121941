.pre-header {
    background-color: $primary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px $body-padding-horizontal;

    .phone-number {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin: 0 30px;
        font-size: 12px;
        color: white;
        @include for-tablet-portrait-only {
            margin: 0 15px;
        }
        @include for-phone-only {
            margin: 0 10px;
            font-size: 10px;
        }

        .icon-phone {
            width: 20px;
            height: 20px;
        }
        p {
            margin-left: 5px;
            span {
                @include for-phone-only {
                    display: block;
                }
                &:first-child {
                    font-weight: $fontBold;
                    padding-bottom: 2px;
                }
                &:last-child {
                    font-weight: $fontLight;
                }
            }
        }
    }
}

header {
    position: relative;
    box-shadow: 0 4px 5px rgba(black, 0.5);
    z-index: 2;
    background-color: $alt-color;

    .inner-header {
        display: flex;
        align-items: center;
        @extend %content-section;
        padding: 15px $body-padding-horizontal; // override content section
        @include for-tablet-landscape-down {
            justify-content: space-between;
        }
        @include for-phone-only {
            padding: 10px $body-padding-horizontal;
        }
    }

    .nav-icon-link {
        @extend %button-reset;
        display: none;
        width: 30px;
        height: 30px;
        @include for-tablet-landscape-down {
            display: block;
        }
        @include for-phone-only {
            width: 24px;
            height: 24px;
        }
    }

    .logo-wrapper {
        text-decoration: none;
        color: $primary-color;

        @include for-tablet-landscape-down {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .logo {
        width: 200px;
        margin: -5px 30px 0 0;
        @include for-tablet-landscape-down {
            margin: -5px 0 0 0;
        }
        @include for-custom-break($tablet-portrait-start, $tablet-landscape-end) {
            width: 175px;
        }
        @include for-phone-only {
            width: 150px;
        }
        .logo-cls-1 {
            fill: $accent-color;
        }
        .logo-cls-2,
        .logo-cls-3 {
            fill: $primary-color;
        }
        .logo-cls-3 {
            font-size: 16px;
            font-weight: 800; 
        }
    }
    .header-tagline {
        font-weight: $fontBlack;
        font-size: 12px;
        font-style: italic;
        color: $primary-color;
    }

    &.show-nav {
        @include for-tablet-landscape-down {
            .nav {
                margin-left: 0;
            }
            .nav-overlay {
                right: 0; 
                display: block;
            }
        }
    }

    .nav-overlay {
        background-color: rgba(black, 0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 499;
        display: none;
    }

    .nav {
        display: flex;
        margin-left: auto;
        padding: 0 20px;

        @include for-tablet-landscape-down {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            z-index: 500;
            background-color: $primary-color;
            padding: 20px 0;
            width: 300px;
            margin-left: -300px;
            transition: all 0.2s;
            overflow-x: hidden;
            overflow-y: auto;
            flex-flow: column nowrap;          
        }

        li {
            position: relative;
            
            &:first-child {
                display: none;
                @include for-tablet-landscape-down {
                    display: block;
                }
            }

            .nav-close-link {
                @extend %button-reset;
                display: block;
                width: 25px;
                height: 25px;
                margin: 0 0 20px 24px;
            }

            .nav-link {
                @extend %button-reset;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 24px;
                color: $primary-color;
                font-size: 14px;
                font-weight: $fontBlack;
                text-decoration: none;
                transition: all 150ms ease-in-out;

                @include for-desktop-up {
                    &:hover,
                    &:focus,
                    &:active {
                        color: rgba($primary-color, 0.8);
                    }
                }

                @include for-tablet-landscape-down {
                    justify-content: space-between;
                    color: white;
                    padding: 12px 24px;
                    font-size: 18px;
                }
    
                .icon-arrow {
                    margin: -8px 0 0 10px;
                    border-bottom: 2px solid rgba($primary-color, 0.8);
                    border-left: 2px solid rgba($primary-color, 0.8);

                    @include for-tablet-landscape-down {
                        margin: -8px 0 0 0;
                        transform: rotate(-135deg);
                    }
                }
                
                &.active {
                    color: white;
    
                    .icon-arrow {
                        border-color: white;
                        border-width: 3px;

                        @include for-tablet-landscape-down {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
            .nav-link.has-sub-nav.active + .sub-nav {
                display: block;
            } 
        }

        .sub-nav {
            position: absolute;
            width: 200px;
            padding: 8px 0;
            background-color: white;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            display: none;

            @include for-tablet-landscape-down {
                position: relative;
                background-color: rgba(white, 0.1);
                box-shadow: none;
                width: 100%;
                font-size: 16px;
            }

            a {
                display: block;
                padding: 8px 16px;
                color: #888;
                transition: padding 0.2s;
                text-decoration: none;

                @include for-desktop-up {
                    &:hover,
                    &:focus,
                    &:active {
                        color: white;
                        background-color: $primary-color;
                        padding: 12px 10px 12px 22px;
                    }
                }

                @include for-tablet-landscape-down {
                    padding: 12px 34px;
                    color: rgba(white, 0.8);
                }
            }
        }
    }
    .site-search {
        width: 20px;
        height: 20px;
        @include for-tablet-landscape-down {
            padding: 5px;
            width: 30px;
            height: 30px;
        }

        .icon {
            display: none;
        }
    }
}
